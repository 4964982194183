body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #5300f4 !important;
}
/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline {
  border-color: #5300f4 !important;
} */
.css-vnkopk-MuiStepLabel-iconContainer {
  padding-right: 14px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  font-size: 1.6rem !important;
}

.css-1pe7n21-MuiStepConnector-root {
  margin-left: 0.8rem !important;
  min-height: 45px !important;
}

.css-8t49rw-MuiStepConnector-line {
  min-height: 45px !important;
  /* border-color: #5300f4 !important; */
  border-left-style: dashed !important;
}

.css-1hv8oq8-MuiStepLabel-label {
  font-size: 14px !important;
}

.css-1sn4lm3-MuiTypography-root {
  font-size: 16px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  fill: #dcdae3 !important;
}

.css-117w1su-MuiStepIcon-text {
  display: none !important;
}

.timer {
  font-family: "sans-serif";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}
.css-ahj2mt-MuiTypography-root {
  font-weight: 600 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  fill: #5300f4 !important;

}